.unit-properties-v3 {

	.title {
		font-size: var(--h4-font-size);
		font-weight: var(--h4-font-weight);
		margin-bottom: 1rem;
	}

	.tick-li {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 20px;

		@media (max-width:992px) {
			font-size: var(--font-size-md);
		}


		li {
			width: 50%;
			padding-left: 30px;
			padding-right: 15px;
			min-height: 30px;
			position: relative;

			@media (max-width:480px) {
				width: 100%;
			}

			&:before {
				content: "\f00c";
				font-family: "Font Awesome 5 Free";
				-webkit-font-smoothing: antialiased;
				font-weight: 600;
				display: inline-block;
				position: absolute;
				left: 0;
				top: 0;
				color: var(--color-primary);
			}
		}
	}
}