.search-page-layout-v1 {
	background-color: var(--color-grey-light);
	margin-top: var(--page-margin-top);

	.list-view {
		.search-map-pane-v1 {
			display: none;
		}
	}

}