.unit-card-v1 {

	.content {
		padding: 10px;
		display: flex;
		flex-direction: column;
		min-height: 190px;
		justify-content: space-between;
		position: relative;
		margin: 0 25px;
		top: -35px;
		-webkit-box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.36);
		box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.36);
		background-color: var(--white);
	}

	.region-type {
		font-size: var(--font-size-md);
	}

	.fa-map-marker-alt {
		padding-left: -10px;
		padding-right: 5px;
	}

	.label-container {
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;
		align-items: center;

		.label {
			padding: 5px 10px;
			font-weight: 500;
			width: fit-content;
			font-weight: 600;
			color: initial;
		}

		.book {
			cursor: pointer;
			border-radius: 5px;
			transition: 0.25s;
			text-decoration: none;

			&:hover {
				background-color: var(--color-primary);
				color: var(--color-white);
				text-decoration: none;
			}

			span:nth-of-type(1) {
				font-size: 10px;
			}
		}

		.customer-rating {
			font-weight: 500;
			color: initial;
			display: flex;
			flex-direction: column;
			align-items: center;

			span:nth-child(2) {
				font-size: 9px;
			}
		}
	}

	.object-name {
		font-size: var(--font-size-h3);
		font-weight: bold;
	}

	.comodities {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}


	.comodity {
		padding: 5px;
	}

	.image-wrap {
		position: relative;



		img {
			width: 100%;
			transition: 0.5s;

			&:hover {

				filter: brightness(0.7);

			}
		}

		.stars {
			color: #DAA520;
			background-color: white;
			display: flex;
			align-items: center;
		}

		.rules {
			background-color: white;
			padding: 5px;
		}

		.favorite {
			position: absolute;
			top: 10px;
			right: 10px;
			color: white;
			font-size: 1.5em;
		}
	}


	.ribbon {
		position: absolute;
		left: 10px;
		background: var(--color-red);
		top: 10px;
		padding: 3px 20px;
		border-radius: 10px;
		font-size: 13px;
		font-weight: 600;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
	}

}