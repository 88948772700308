.blogentry-v1 {
	.img-thumbnail {
		height: 100% !important;
	}

	.date {
		padding-top: 10px;
		font-size: var(--font-size-md);
	}

	.content-block {
		box-shadow: 0 0.1rem 1rem rgb(0 0 0 / .1);
	}

	.img-col {
		position: relative;

		.percent {
			position: absolute;
			left: 0px;
			font-size: 20px;
			font-weight: bold;
			color: var(--color-white);
			background-color: var(--color-red);
			padding: 10px;
		}
	}

	.offer-title {
		padding: 10px 0px;
		font-size: 1.2rem;
		font-weight: bold;
	}


	.image-bl {
		height: 700px;

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}

		@media (max-width:992px) {
			height: 370px;
		}
	}

	.blogcontent {
		.row {
			&.flex-row-reverse {
				.blog-cont-bl {
					left: inherit;
					right: -100px;

					@media (max-width:992px) {
						right: 0;
					}
				}
			}

			>div {
				padding: 0;
			}

			background-color: var(--color-grey-light);
			align-items: center;

			@media (max-width:992px) {
				background-color: var(--color-white);
			}

			.blog-cont-bl {
				padding: 40px 36px;
				position: relative;
				left: -100px;
				z-index: 5;
				background-color: var(--color-white);

				@media (max-width:992px) {
					left: 0;
				}
			}
		}
	}



}