.search-result-header-v2 {

	padding: 0 15px 15px;

	.srt-bl-top {
		.flex-align-center {
			@media (max-width:992px) {
				display: block !important;
				text-align: right;
			}

			.btn-filter {
				font-size: var(--font-size-md);

				@media (min-width:993px) {
					border-right: 1px solid var(--color-grey-dark);
					padding-right: 5px;
					margin-right: 5px;
					margin-bottom: 0;
					top: 0;
				}

				display: inline-block;
				margin-bottom: 5px;
				color: var(--color-secondary);
				position: relative;
				top:-6px;

				&:hover {
					color: var(--color-primary);
				}
			}
		}
	}

	.srt-bl-top {
		border-bottom: 1px solid var(--color-grey-dark);
		padding-bottom: 15px;
		margin-bottom: 15px;
		margin-top: 20px;
	}

	.sort-price-new .form-control {
		background-position: 93% 15px;
	}



}