.faq-v1 {
	margin-top: var(--page-margin-top);

	.panel-heading {
		padding: 12px;
		background-color: var(--faq-title-bg-color);
		margin-bottom: 20px;
	}

	.accordion-link {
		text-decoration: none;
	}

	.panel-title {
		margin: 0;
		color: var(--faq-title-font-color);
		text-decoration: none;
		font-size: var(--font-size-main);
	}

	.panel-title>a {
		color: var(--faq-title-font-color);
		text-decoration: none;
		font-size: var(--font-size-main);
	}

	.panel-body {
		padding: 0px 10px;
	}

	.card {
		background-color: transparent;
		border: 1px solid var(--color-grey-normal);

		.card-header {
			background-color: var(--faq-title-bg-color);
			border-bottom: none;
			padding: 0px 5px;
		}

	}

	.btn-link {
		font-weight: bold;
		color: var(--faq-title-font-color);
		text-decoration: none;
		width: 100%;
		text-align: left;
		font-size: var(--font-size-main);

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}
	}
}