.unit-photos-v2 {
	.unitPhotos {
		margin: 0 -5px;

		.imgcols {
			width: 33.33%;
			position: relative;
			overflow: hidden;
			margin-bottom: 10px;
			float: left;
			padding: 0 5px;
			height: 262px;


			&:before {
				content: "";
				background-color: rgba(255, 255, 255, 0);
				position: absolute;
				left: 0;
				top: 0;
				opacity: 0;
				visibility: hidden;
				transition: all .5s ease-in-out;
				opacity: 1;
				width: calc(100% - 10px);
				height: 100%;
				left: 5px;
			}

			&:hover {
				&:before {
					opacity: 1;
					visibility: visible;
					background-color: rgba(255, 255, 255, .3);
				}
			}

			@media (max-width:640px) {
				width: 50% !important;
				height: 110px !important;
			}

			&:first-child,
			&:nth-child(11),
			&:nth-child(21),
			&:nth-child(31),
			&:nth-child(41),
			&:nth-child(51) {
				width: 66.66%;
				float: left;
				height: 262px;


				img {
					width: 100%;
					height: 100%;
				}
			}


			&:nth-child(2),
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(8),
			&:nth-child(9),
			&:nth-child(10),
			&:nth-child(12),
			&:nth-child(13),
			&:nth-child(14),
			&:nth-child(15),
			&:nth-child(16),
			&:nth-child(18),
			&:nth-child(19),
			&:nth-child(20),
			&:nth-child(22),
			&:nth-child(23),
			&:nth-child(24),
			&:nth-child(25),
			&:nth-child(26),
			&:nth-child(28),
			&:nth-child(29),
			&:nth-child(30) {
				width: 33.33%;
			}

			&:nth-child(7),
			&:nth-child(16),
			&:nth-child(27),
			&:nth-child(37),
			&:nth-child(47) {
				width: 66.66%;
				float: right;
				height: 262px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			@media (max-width:640px) {
				width: 100%;
			}


			img {
				-o-object-fit: cover;
				object-fit: cover;
				-o-object-position: center center;
				object-position: center center;
				height: 100%;
				width: 100%;

			}
		}
	}

}